body {
	font-family: "Comic Sans MS", "Comic Sans", cursive;
	background-image: url("90sBackground.jpg");
	background-color: antiquewhite;
}

.App {
	text-align: center;
	color: white;
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.Visitors {
	margin-top: 17px;
}

.Visitors span {
	font-size: 1.47em;
}
.Visitors img {
	height: 48px;
	vertical-align: middle;
	padding-bottom: 4px;
}

.MadeFor {
	margin-top: 17px;
}

.MadeFor span {
	font-size: 1em;
}
.MadeFor img {
	height: 30px;
	vertical-align: middle;
	padding-bottom: 4px;
}
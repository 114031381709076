.progress-bar-wrapper {
	width: 75%;
	height: calc(10px + 2vmin);
	border: black 1px solid;
    border-radius: 9px;
    overflow: hidden;
	margin-top: 10px;
}

.progress {
	height: 100%;
	width: 100%;
	background-color: aqua;
	transition: transform 1s;
	transition-timing-function: linear;
}
table, td, th {
	width: 75%;
	border-width: 4px;
	border-color: white;
	border-style: double;
	border-collapse: collapse;
	margin-top: 1em;
}

tr > th, tr > td {
	width: 0%;
	word-wrap: break-word;
}

.albumArtFrame {
	border: black solid 1px;
}

.croppingAlbumArt {
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}

.cover {
	filter: contrast(500%); /* drop-shadow(0px 0px 10px gray); Looks too good for the 90s */
}